<!--
 * @Author: lyt
 * @Date: 2024-10-22 01:35:05
 * @LastEditTime: 2024-10-22 15:45:48
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-投资者服务模块
 * @FilePath: /official_web_portal/official/src/components/phone/ServiceComp.vue
 *  
-->
<template>
    <div class="serviceComp">
        <div class="list">
            <a :href="item?.href" v-for="(item, index) in serviceList" :key="index" target="_blank">
                <div class="item" :style="{ backgroundImage: `url(${item?.bgImgUrl})` }">
                    <img :src="item?.icon">
                    <div class="txt-box">
                        <div class="h3">{{ item?.title }}</div>
                        <p>{{ item?.content }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServiceComp',
    props: {
    },
    data() {
        return {
            serviceList: [{
                icon: "https://img.i2soft.net/i2official-web/assets/images/relations/adder.png",
                title: "地址",
                content: "上海市浦东新区陆家嘴街道陆家嘴环路958号华能联合大厦4楼",
                bgImgUrl: "https://img.i2soft.net/i2official-web/assets/images/relations/Adress_back.png",
                href: "",
            }, {
                icon: "https://img.i2soft.net/i2official-web/assets/images/relations/phone.png",
                title: "投资者热线",
                content: "021-68816719",
                bgImgUrl: "https://img.i2soft.net/i2official-web/assets/images/relations/Phone_back.png",
                href: "",
            }, {
                icon: "https://img.i2soft.net/i2official-web/assets/images/relations/email.png",
                title: "投资者邮箱",
                content: "public@i2finance.net",
                bgImgUrl: "https://img.i2soft.net/i2official-web/assets/images/relations/Email_back.png",
                href: "",
            }, {
                icon: "https://img.i2soft.net/i2official-web/assets/images/relations/help.png",
                title: "投资者互动平台",
                content: "立即跳转",
                bgImgUrl: "https://img.i2soft.net/i2official-web/assets/images/relations/Help_back.png",
                href: "https://ir.p5w.net/c/830799.shtml",
            }],
        };
    },
    created() {
        window.scrollTo(0, 540);
    },
    mounted() {
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {

        getScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.serviceComp {
    .list {
        a {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 40px;
            text-decoration: none;
        }

        .item {
            width: 100%;
            height: 200%;
            padding: 20px 30px;
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            row-gap: 40px;
            background-size: cover;
            margin-bottom: 10px;

            img {
                width: 25px;
                height: 25px;
            }

            .txt-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .h3 {
                    font-size: 14.01px;
                    font-weight: normal;
                    line-height: 16.93px;
                    letter-spacing: 0px;

                    color: #333333;
                }

                p {
                    font-size: 10.51px;
                    font-weight: normal;
                    line-height: 12.85px;
                    letter-spacing: 0px;

                    color: #666666;
                }
            }
        }

    }

}
</style>
