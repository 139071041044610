<!--
 * @Author: lyt
 * @Date: 2024-10-22 01:34:44
 * @LastEditTime: 2024-10-22 17:45:22
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-业绩报告模块
 * @FilePath: /official_web_portal/official/src/components/phone/AchievementComp.vue
 *  
-->
<template>
    <div class="achievementComp">
        <div class="block">
            <h3 class="h3">年报</h3>
            <div class="list" v-for="annual in annualList" :key="annual.id">
                <a :href="annual?.to" target="_blank">
                    <div class="item">
                        <div class="left_box">
                            <img
                                src="https://img.i2soft.net/i2official-web/assets/images/relations/phone/announcement_icon.svg">
                        </div>
                        <div class="right_box">
                            <div>{{ annual.name }}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="block">
            <h3 class="h3">中报</h3>
            <div class="list" v-for="Inter in InterimList" :key="Inter.id">
                <a :href="Inter?.to" target="_blank">
                    <div class="item">
                        <div class="left_box">
                            <img
                                src="https://img.i2soft.net/i2official-web/assets/images/relations/phone/announcement_icon.svg">
                        </div>
                        <div class="right_box">
                            <div>{{ Inter.name }}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="block">
            <h3 class="h3">季报</h3>
            <div class="list" v-for="Quart in QuarterlyList" :key="Quart.id">
                <a :href="Quart?.to" target="_blank">
                    <div class="item">
                        <div class="left_box">
                            <img
                                src="https://img.i2soft.net/i2official-web/assets/images/relations/phone/announcement_icon.svg">
                        </div>
                        <div class="right_box">
                            <div>{{ Quart.name }}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AchievementComp',
    props: {
    },
    data() {
        return {
            // 年报
            annualList: [],
            // 中报
            InterimList: [],
            // 季报
            QuarterlyList: [],
            datas: [],
            query_type: "",
            page: {
                totle: '',
                currentPage: 2,
                pageSize: 8
            },
            staic: '',
        };
    },
    filters: {},
    created() {
        this.getachievent();
        this.query_type = this.$route.query.category;
        console.log("this.query_type", this.query_type);
        window.scrollTo(0, 540);
    },
    mounted() {
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {
        // 获取业绩表
        getachievent() {
            // 年报业报
            this.$axios.get("/parameter/annualList").then((res) => {
                this.annualList = res.data.data;
                console.log("年报业报", this.annualList)
            })
            // 中报业报
            this.$axios.get("/parameter/InterimList").then((res) => {
                this.InterimList = res.data.data;
                console.log("中报业报", this.InterimList)
            })
            // 季报业报
            this.$axios.get("/parameter/QuarterlyList").then((res) => {
                this.QuarterlyList = res.data.data;
                console.log("季报业报", this.QuarterlyList)
            })
        },
        getScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.achievementComp {
    .block {
        .list {
            display: flex;
            flex-direction: column;

            a {
                text-decoration: none;
            }

            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                column-gap: 15px;
                background: #F8F8F8;
                margin-bottom: 10px;
                padding: 10px 0;

                .left_box {
                    padding: 0 12px;
                }

                .right_box {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;

                    div {
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 14px;
                        letter-spacing: 0.9px;
                        text-align: left;
                        color: #333333;
                    }


                }
            }
        }
    }


}
</style>
