<!--
 * @Author: lyt
 * @Date: 2024-10-22 01:02:00
 * @LastEditTime: 2024-10-22 17:44:57
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-公司治理模块
 * @FilePath: /official_web_portal/official/src/components/phone/GovernmentComp.vue
 *  
-->
<template>
    <div class="governmentComp">
        <!-- 人事管理 -->
        <div class="management">
            <div class="list">
                <div class="item">
                    <h3>董事会</h3>
                    <div class="block">
                        <div class="box">
                            <h4 class="h4">张岩先生</h4>
                            <h3 class="h3">董事长、总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4"> 吴臻女士</h4>
                            <h3 class="h3">董事、副总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">杨光润先生</h4>
                            <h3 class="h3">董事、副总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">张甫先生</h4>
                            <h3 class="h3">董事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">王涛先生</h4>
                            <h3 class="h3">董事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">周忠恳先生</h4>
                            <h3 class="h3">董事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">周宁女士</h4>
                            <h3 class="h3">独立董事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">谭毓安先生</h4>
                            <h3 class="h3">独立董事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">朱以林先生</h4>
                            <h3 class="h3">独立董事</h3>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <h3>监事会</h3>
                    <div class="block">
                        <div class="box">
                            <h4 class="h4">柳红芳女士</h4>
                            <h3 class="h3">监事会主席</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">胡超先生</h4>
                            <h3 class="h3">监事</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">彭小波先生</h4>
                            <h3 class="h3">监事</h3>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <h3>高级管理层</h3>
                    <div class="block">
                        <div class="box">
                            <h4 class="h4">张岩先生</h4>
                            <h3 class="h3">董事长、总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">吴臻女士</h4>
                            <h3 class="h3">董事、副总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">杨光润先生</h4>
                            <h3 class="h3">董事、副总经理</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">王涛先生</h4>
                            <h3 class="h3">董事、董事会秘书、财务总监</h3>
                        </div>
                        <div class="box">
                            <h4 class="h4">夏恒敏女士</h4>
                            <h3 class="h3">财务副总监</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 公司章程 -->
        <div class="charter">
            <div class="inner-tit center gray">
                <h3>公司章程</h3>
            </div>
            <div class="cont">
                <a href="https://www.bse.cn/disclosure/2023/2023-10-27/a5c6cccf6ab941e3908961f7979813b0.pdf"
                    target="_blank">
                    <div class="img-box">
                        <img src="https://img.i2soft.net/i2official-web/assets/images/relations/constitution_back.png"
                            alt="">
                    </div>
                    <div class="txt-box">
                        <div class="h4">上海艾融软件股份有限公司</div>
                        <div class="h3">章 程</div>
                        <div class="tip">（2023年11月14日经公司2023年第三次临时股东大会审议通过）</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GovernmentComp',
    props: {
    },
    data() {
        return {
            // 年报
            annualList: [],
            // 中报
            InterimList: [],
            // 季报
            QuarterlyList: [],
            shareholderList: [],
        };
    },
    filters: {},
    created() {
        this.getachievent();
    },
    mounted() {
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {
        // 获取业绩表
        getachievent() {
            // 股东权益
            this.$axios.get("/parameter/shareholderList").then((res) => {
                this.shareholderList = res.data.data;
                console.log("年报业报", this.shareholderList)
            })
        },

        getScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.governmentComp {

    .management {
        .list {
            .item {

                .block {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;

                    .box {
                        padding: 20px 30px;
                        // width: 172px;
                        // height: 93px;
                        opacity: 1;

                        background: #EAEFF3;

                        .h4 {
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 14px;
                            text-align: center;
                            letter-spacing: 0px;

                            color: #666666;
                        }

                        .h3 {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 16px;
                            text-align: center;
                            letter-spacing: 0px;

                            color: #333333;
                        }
                    }

                    .box:hover {
                        background-image: url("https://ebird.oss-cn-shanghai.aliyuncs.com/official-web/assets/images/relations/direct_back.png");
                        background-size: 100%;
                        box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

                        .h4 {
                            color: #fff;
                        }

                        .h3 {
                            color: #fff;

                        }


                    }

                    .box:active {

                        background-image: url("https://ebird.oss-cn-shanghai.aliyuncs.com/official-web/assets/images/relations/direct_back.png");
                        background-size: 100%;
                        box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

                        .h4 {
                            color: #fff;
                        }

                        .h3 {
                            color: #fff;

                        }

                    }
                }
            }
        }
    }

    .charter {

        .center {
            text-align: center;
        }

        .cont {
            margin-top: 2vw;
            font-size: 0;

            a {
                width: 100%;
                display: flex;
                text-decoration: none;
                color: #333;

                .img-box {
                    width: 120px;
                    overflow: hidden;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: left;
                    }

                }

                .txt-box {
                    width: 100%;
                    padding: 6.25vw 3.125vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(90deg, #245DA1 27%, #3486C3 100%);
                    color: #ffffff;

                    .h4 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }

                    .h3 {
                        font-size: 22px;
                        margin-bottom: 23px;
                    }

                    .tip {
                        font-size: 11px;
                    }
                }
            }
        }
    }


}
</style>
